import React from "react";
import TextField from "@mui/material/TextField";

function AssignmentTitle({ formData, setFormData }) {
  return (
    <div className="enter-assignment-title-container">
      <div className="prompt">Please enter the assignment title</div>
      <TextField
        variant="outlined"
        label="Title"
        value={formData.assignmentTitle}
        onChange={(event) =>
          setFormData({ ...formData, assignmentTitle: event.target.value })
        }
      ></TextField>
    </div>
  );
}

export default AssignmentTitle;
