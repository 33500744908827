import { API, graphqlOperation } from "aws-amplify";

import moment from "moment";

import {
  checkExistingExtension,
  getStudentModules,
} from "../../graphql/queries";
import { submitExtensionRequest } from "../../graphql/mutations";

//API call to check if there is an existing extension
export const apiCheckExistingExtensionRequest = async () => {
  const results = { success: true, body: {}, message: "" };
  try {
    const response = await API.graphql(
      graphqlOperation(checkExistingExtension)
    );
    results.body = response.data.checkExistingExtension;
  } catch (err) {
    console.log(err);
    results.success = false;
    results.message =
      "Unable to check for existing extension. Please try again or contact an administrator";
  }
  return results;
};

//API call to fetch associated student modules
export const apiGetStudentModules = async () => {
  const results = { success: true, body: {}, message: "" };
  try {
    const response = await API.graphql(graphqlOperation(getStudentModules));
    results.body = response.data.getStudentModules;
  } catch (err) {
    console.log(err);
    results.success = false;
    results.message =
      "Unable to retrieve student modules. Please try again or contact an administrator";
  }
  return results;
};

//API call to submit multi-step form data
export const apiSubmitExtensionRequest = async (formData) => {
  let results = { success: true, body: {}, message: "" };

  //Format date into ISO format
  const deadlineDate = formData.deadline;
  const deadlineDateMonth = deadlineDate.$M + 1;
  const formattedDate = moment(
    `${deadlineDate.$y}-${deadlineDateMonth}-${deadlineDate.$D}T00:00:00`,
    "YYYY-MM-DD HH:mm:ss"
  ).toDate();
  const isoDate = formattedDate.toISOString();

  const requestDetails = {
    academicYearCode: formData.module.academicYearCode,
    semesterCode: formData.module.semesterCode,
    moduleCode: formData.module.moduleCode,
    assignmentTitle: formData.assignmentTitle,
    assignmentDeadline: isoDate,
    reasonForExtension: formData.reason,
  };

  try {
    const response = await API.graphql(
      graphqlOperation(submitExtensionRequest, { arguments: requestDetails })
    );
    results.body = response.data.submitExtensionRequest;
    results.message = "Approval decision successfully submitted";
  } catch (err) {
    console.log(err);
    results.success = false;
    results.message =
      "Unable to submit extension request. Please try again or contact an administrator";
  }
  return results;
};
