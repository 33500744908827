import { Auth } from "aws-amplify";

const getIdToken = async () => ({
  Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
});

const awsconfig = {
  aws_appsync_graphqlEndpoint: `${process.env.REACT_APP_APPSYNC_API}`,
  aws_appsync_region: `${process.env.REACT_APP_REGION}`,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  graphql_headers: getIdToken,
  Auth: {
    region: `${process.env.REACT_APP_REGION}`,
    userPoolId: `${process.env.REACT_APP_USER_POOL_ID}`,
    userPoolWebClientId: `${process.env.REACT_APP_USER_POOL_CLIENT_ID}`,
    oauth: {
      domain: `${process.env.REACT_APP_USER_POOL_DOMAIN}`,
      scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
      responseType: "code",
      redirectSignIn: `${process.env.REACT_APP_REDIRECT_SIGNIN_URL}`,
      redirectSignOut: `${process.env.REACT_APP_REDIRECT_SIGNOUT_URL}`,
    },
  },
};

export default awsconfig;
