import React from "react";
import "./ExpGroundsPromptPage.css";
import Button from "@mui/material/Button";
import SignOutButton from "../../../shared_components/SignoutButton/SignOutButton";

function ExpGroundsPromptPage({ setStep }) {
  return (
    <div className="exp-grounds-page">
      <div className="exp-grounds-page-container">
        <div className="logo"></div>
        <div className="header">
          <h1>Existing extension</h1>
        </div>
        <div className="body">
          <p>
            You have already requested an extension. Only continue if you are
            requesting an extension based on exceptional grounds.
          </p>
          <Button
            style={{ backgroundColor: "#0da6a1" }}
            variant="contained"
            onClick={() => {
              setStep((setStep) => setStep + 1);
            }}
          >
            Continue
          </Button>
        </div>
        <div className="footer">
          <SignOutButton />
        </div>
      </div>
    </div>
  );
}

export default ExpGroundsPromptPage;
