import React from "react";
import { Auth } from "aws-amplify";
import Button from "@mui/material/Button";

function SignOutButton(props) {
  return (
    <div>
      <Button variant="contained" color="error" onClick={() => Auth.signOut()}>
        Sign out
      </Button>
    </div>
  );
}

export default SignOutButton;
