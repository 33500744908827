import React from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function Deadline({ formData, setFormData }) {
  return (
    <div className="enter-assignment-title-container">
      <div className="prompt">Please enter when the submission deadline is</div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Deadline"
          value={formData.deadline}
          onChange={(date) => {
            setFormData({ ...formData, deadline: date });
          }}
          renderInput={(params) => <TextField {...params} />}
          inputFormat="DD-MM-YYYY"
        />
      </LocalizationProvider>
    </div>
  );
}

export default Deadline;
