import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const BoldText = ({ children }) => {
  return <span style={{ fontWeight: "bold" }}>{children}</span>;
};

function ConfirmationDialog({
  openConfirmationDialog,
  handleConfirmationDialogClose,
  approvalAction,
  handleConfirmationYes,
  setApprovalReason,
  approvalReason,
}) {
  return (
    <div>
      <Dialog
        open={openConfirmationDialog}
        onClose={handleConfirmationDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm decision</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              Confirm you want new status to be{" "}
              <BoldText>{approvalAction}</BoldText>?
            </p>
          </DialogContentText>
          {approvalAction === "Reject" ? (
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 120, width: "80%" }}
            >
              <InputLabel id="Reasons-label">
                Reason for approvalAction
              </InputLabel>
              <Select
                labelId="Reasons-label"
                label="Reasons"
                autoWidth
                value={approvalReason}
                onChange={(event) => {
                  setApprovalReason(event.target.value);
                }}
              >
                <MenuItem value={"Date submitted incorrect"}>
                  Submitted a piece of work that is not eligible.
                </MenuItem>
                <MenuItem value={"Request not applicable with exam"}>
                  Submitted after the deadline.
                </MenuItem>
                <MenuItem value={"Used an extension then submitted on time."}>
                  Used an extension then submitted on time.
                </MenuItem>
                <MenuItem
                  value={
                    "Used an extension but then decide to submit extenuation."
                  }
                >
                  Used an extension but then decide to submit extenuation.
                </MenuItem>
                <MenuItem value={"Duplicate of another request."}>
                  Dulicate of another request.
                </MenuItem>
              </Select>
            </FormControl>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={handleConfirmationDialogClose}
          >
            No
          </Button>
          <Button
            color="success"
            variant="contained"
            onClick={handleConfirmationYes}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConfirmationDialog;
