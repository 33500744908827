import React from "react";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

function AbsenseReason({
  formData,
  setFormData,
  selectedReason,
  setSelectedReason,
  showText,
  setShowText,
}) {
  return (
    <div className="enter-assignment-title-container">
      <div className="prompt">
        Please enter the reason for requesting mitigating circumstances - self
        certification
      </div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="Reasons-label">Reasons</InputLabel>
        <Select
          labelId="Reasons-label"
          label="Reasons"
          autoWidth
          value={selectedReason}
          onChange={(event) => {
            setSelectedReason(event.target.value);
            if (event.target.value === "Other") {
              setShowText(true);
            } else {
              setShowText(false);
              setFormData({ ...formData, reason: event.target.value });
              console.log("Dropdown Edit: " + formData);
            }
          }}
        >
          <MenuItem
            value={"Physical/emotional health (yourself or dependents)"}
          >
            Physical/emotional health (yourself or dependents)
          </MenuItem>
          <MenuItem value={"Short term sickness"}>Short term sickness</MenuItem>
          <MenuItem value={"Travel"}>Travel</MenuItem>
          <MenuItem value={"Financial"}>Financial</MenuItem>
          <MenuItem value={"IT/Internet access"}>IT/Internet access</MenuItem>
          <MenuItem value={"Family Issues"}>Family Issues</MenuItem>
          <MenuItem value={"Working commitments"}>Working commitments</MenuItem>
          <MenuItem value={"Other commitments/priorities"}>
            Other commitments/priorities
          </MenuItem>
          <MenuItem value={"Other"}>Other</MenuItem>
        </Select>
      </FormControl>
      {showText ? (
        <TextField
          sx={{ m: 1, width: "50ch" }}
          variant="outlined"
          label="Reason"
          multiline
          rows={5}
          value={formData.reason}
          onChange={(event) => {
            setFormData({ ...formData, reason: event.target.value });
            console.log("Text Edit: " + formData);
          }}
        ></TextField>
      ) : (
        " "
      )}
    </div>
  );
}

export default AbsenseReason;
