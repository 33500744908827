/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const checkExistingExtension = /* GraphQL */ `
  query CheckExistingExtension {
    checkExistingExtension
  }
`;
export const getStudentModules = /* GraphQL */ `
  query GetStudentModules {
    getStudentModules {
      moduleCode
      semesterCode
      moduleName
      academicYearCode
    }
  }
`;
export const getExtensionRequests = /* GraphQL */ `
  query GetExtensionRequests {
    getExtensionRequests {
      studentCode
      requestAccessKey
      termName
      studentUsername
      academicYearCode
      studentForename
      studentSurname
      semesterCode
      moduleCode
      moduleOccurrence
      moduleName
      moduleOwnerUsername
      moduleOwnerEmail
      status
      documentCreatedDate
      documentUpdatedDate
      assignmentTitle
      assignmentDeadline
      reasonForExtension
      requestID
      reasonForRejection
    }
  }
`;
