import { API, graphqlOperation } from "aws-amplify";

import { getExtensionRequests } from "../../graphql/queries";
import { saveStaffDecision } from "../../graphql/mutations";

//API call to Fetch all extension requests
export const apiGetAllExtensionRequests = async () => {
  const results = { success: true, body: {}, message: "" };
  try {
    const response = await API.graphql(graphqlOperation(getExtensionRequests));
    const extensionRequests = response.data.getExtensionRequests;
    const numberOfRequests = extensionRequests.length;

    if (numberOfRequests === 0) {
      results.success = false;
      results.message = "No extension requests available";
    } else {
      results.body = extensionRequests;
    }
  } catch (err) {
    console.log(err);
    results.success = false;
    results.message =
      "Unable to retrieve extension requests. Please try again or contact an administrator";
  }
  return results;
};

//API call to submit extension request review
export const apiSaveExtensionRequestApproval = async (
  selectedExtensionRequest,
  approvalAction,
  approvalReason
) => {
  let results = { success: true, body: {}, message: "" };

  var requestDetails = {
    studentCode: selectedExtensionRequest.studentCode,
    requestAccessKey: selectedExtensionRequest.requestAccessKey,
    approval_decision: approvalAction === "Approve" ? "APPROVED" : "REJECTED",
    approval_reason: approvalReason,
  };
  try {
    const response = await API.graphql(
      graphqlOperation(saveStaffDecision, { arguments: requestDetails })
    );
    results.body = response;
    results.message = "Approval decision successfully submitted";
  } catch (err) {
    console.log(err);
    results.success = false;
    results.message =
      "Unable to submit approval. Please try again or contact an administrator";
  }
  return results;
};
