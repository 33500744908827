/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const submitExtensionRequest = /* GraphQL */ `
  mutation SubmitExtensionRequest($arguments: submitExtensionRequestInput) {
    submitExtensionRequest(arguments: $arguments)
  }
`;
export const saveStaffDecision = /* GraphQL */ `
  mutation SaveStaffDecision($arguments: saveStaffDecisionInput) {
    saveStaffDecision(arguments: $arguments)
  }
`;
