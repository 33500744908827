import React, { useEffect, useRef, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";

import LandingPage from "./components/LandingPage/LandingPage";
import Form from "./components/Form/Form";
import SubmissionPage from "./components/SubmissionPage/SubmissionPage";
import ExpGroundsPromptPage from "./components/ExpGroundsPromptPage/ExpGroundsPromptPage";
import NotificationBar from "../shared_components/NotificationBar/NotificationBar";

import { apiCheckExistingExtensionRequest } from "./services/student.services";

// Step controller - renders UI components depending on the current step
function StudentWorkflow(props) {
  const [step, setStep] = useState(0);
  const existingExtension = useRef(false);
  const [extensionCheckComplete, setExtensionCheckComplete] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  // Check for existing extension
  const checkExistingExtension = () => {
    apiCheckExistingExtensionRequest().then((results) => {
      if (results.success) {
        existingExtension.current = results.body;
        setExtensionCheckComplete(true);
      } else {
        setNotificationMessage(results.message);
        setOpenNotification(true);
      }
    });
  };

  //Trigger on first render
  useEffect(() => {
    checkExistingExtension();
  }, []);

  //Render exceptional grounds page if existing extension exists, otherwise proceed to next multi-step form
  const isExistingExtensionSubWorkflow = () => {
    if (existingExtension.current) setStep((setStep) => setStep + 1);
    else setStep((setStep) => setStep + 2);
  };

  //Close notification close
  const handleNotificationClose = () => {
    setOpenNotification(false);
  };

  const stepDisplay = () => {
    switch (step) {
      case 0:
        return (
          <LandingPage
            setStep={setStep}
            extensionCheckComplete={extensionCheckComplete}
            existingExtension={existingExtension}
          />
        );
      case 1:
        return isExistingExtensionSubWorkflow();
      case 2:
        return <ExpGroundsPromptPage setStep={setStep} />;
      case 3:
        return (
          <Form
            setStep={setStep}
            existingExtension={existingExtension}
            setNotificationMessage={setNotificationMessage}
            setOpenNotification={setOpenNotification}
          />
        );
      case 4:
        return <SubmissionPage existingExtension={existingExtension} />;
      default:
        setNotificationMessage(
          "Step transition error. Please contact system administrator"
        );
        setOpenNotification(true);
    }
  };

  return (
    <div>
      <div className="step-page-display">{stepDisplay()}</div>
      <div className="notification-bar">
        <NotificationBar
          openNotification={openNotification}
          handleNotificationClose={handleNotificationClose}
          notificationMessage={notificationMessage}
        />
      </div>
    </div>
  );
}

export default StudentWorkflow;
