import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

// Convert ISO date time to human friendly format
const dateTimeFormatter = (iso_format) => {
  if (iso_format !== undefined && iso_format !== "N/A" && iso_format !== null) {
    const formatted_date_time = new Date(iso_format).toString();
    const date = formatted_date_time.substring(0, 15);
    const time = formatted_date_time.substring(16, 24);
    return `${date} / ${time}`;
  }
  return iso_format;
};

// Convert ISO date to human friendly format
const dateFormatter = (iso_format) => {
  if (iso_format !== undefined && iso_format !== "N/A" && iso_format !== null) {
    const formatted_date_time = new Date(iso_format).toString();
    return formatted_date_time.substring(0, 15);
  }
  return iso_format;
};

function RequestDialog({
  openRequestDialog,
  handleRequestDialogClose,
  selectedExtensionRequest,
  handleConfirmationDialogOpen,
}) {
  return (
    <div>
      <Dialog
        open={openRequestDialog}
        onClose={handleRequestDialogClose}
        maxWidth={"lg"}
      >
        <DialogTitle>Extension Request Details</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%" }}>
            <Grid container rowSpacing={2} columnSpacing={2}>
              <Grid item xs={12}>
                <Divider variant="middle" />
                <h1>Request Details</h1>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="ID"
                  defaultValue={selectedExtensionRequest.requestID}
                  variant="filled"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Creation Date / Time"
                  defaultValue={dateTimeFormatter(
                    selectedExtensionRequest.documentCreatedDate
                  )}
                  variant="filled"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Decision Date / Time"
                  defaultValue={
                    selectedExtensionRequest.documentUpdatedDate
                      ? dateTimeFormatter(
                          selectedExtensionRequest.documentUpdatedDate
                        )
                      : "N/A"
                  }
                  variant="filled"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Status"
                  defaultValue={selectedExtensionRequest.status}
                  variant="filled"
                  sx={
                    selectedExtensionRequest.status === "APPROVED" ||
                    selectedExtensionRequest.status === "AUTOAPPROVED"
                      ? {
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "green",
                          },
                        }
                      : selectedExtensionRequest.status === "REJECTED"
                      ? {
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "red",
                          },
                        }
                      : {
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "black",
                          },
                        }
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Reason for rejection"
                  defaultValue={selectedExtensionRequest.reasonForRejection}
                  variant="filled"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Divider variant="middle" />
                <h1>Student Details</h1>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  label="Code"
                  defaultValue={selectedExtensionRequest.studentCode}
                  variant="filled"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  label="Username"
                  defaultValue={selectedExtensionRequest.studentUsername}
                  variant="filled"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  label="Forename"
                  defaultValue={selectedExtensionRequest.studentForename}
                  variant="filled"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  label="Lastname"
                  defaultValue={selectedExtensionRequest.studentSurname}
                  variant="filled"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Divider variant="middle" />
                <h1>Assignment Details</h1>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Title"
                  defaultValue={selectedExtensionRequest.assignmentTitle}
                  variant="filled"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Module Name"
                  defaultValue={selectedExtensionRequest.moduleName}
                  variant="filled"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  disabled
                  label="Deadline"
                  defaultValue={dateFormatter(
                    selectedExtensionRequest.assignmentDeadline
                  )}
                  variant="filled"
                  error
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  label="Module Code"
                  defaultValue={selectedExtensionRequest.moduleCode}
                  variant="filled"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  label="Module Occurrence"
                  defaultValue={selectedExtensionRequest.moduleOccurrence}
                  variant="filled"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  label="Semester Code"
                  defaultValue={selectedExtensionRequest.semesterCode}
                  variant="filled"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  label="Academic Year Code"
                  defaultValue={selectedExtensionRequest.academicYearCode}
                  variant="filled"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Divider variant="middle" />
                <h1>Module Owner Details</h1>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  label="Username"
                  defaultValue={selectedExtensionRequest.moduleOwnerUsername}
                  variant="filled"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  disabled
                  label="Email"
                  defaultValue={selectedExtensionRequest.moduleOwnerEmail}
                  variant="filled"
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Divider variant="middle" />
                <h1>Reason</h1>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled
                  label="Reason"
                  defaultValue={selectedExtensionRequest.reasonForExtension}
                  variant="filled"
                  fullWidth
                  multiline
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleConfirmationDialogOpen("Approve")}
            disabled={
              selectedExtensionRequest.status === "APPROVED" ||
              selectedExtensionRequest.status === "AUTOAPPROVED" ||
              selectedExtensionRequest.status === "REJECTED"
            }
          >
            Approve
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleConfirmationDialogOpen("Reject")}
            disabled={
              selectedExtensionRequest.status === "APPROVED" ||
              selectedExtensionRequest.status === "REJECTED"
            }
          >
            Reject
          </Button>
          <Button
            style={{ backgroundColor: "#0da6a1" }}
            variant="contained"
            onClick={handleRequestDialogClose}
          >
            Exit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RequestDialog;
