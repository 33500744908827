import React from "react";
import "./LandingPage.css";
import Button from "@mui/material/Button";
import SignOutButton from "../../../shared_components/SignoutButton/SignOutButton";

function LandingPage({ setStep, extensionCheckComplete, existingExtension }) {
  return (
    <div className="landing-page">
      <div className="landing-page-container">
        <div className="logo"></div>
        <div className="header">
          <h1>
            Use of allocated mitigating circumstances - self certification
            (additional 7 calendar days)
          </h1>
        </div>
        <div className="body">
          <p>
            You are entitled to use two mitigating circumstances – self
            certification extensions per term (also known as semester) for
            eligible pieces of coursework. This provides you with an extension
            for up to 7 days (formerly called automatic extensions).
          </p>
          <p>
            If you are eligible to use a self certification extension for an
            assessment, you must fully complete this form including providing a
            reason for using your extension.
          </p>
          <p>
            If you are uncertain if your assignment is eligible for a self
            certification extension, please seek advice from your module leader.
          </p>
          <p>
            You need to complete the online form before 23:59hrs on the deadline
            date (UK time).
          </p>
          <p>
            Your name and student ID will be automatically collected because you
            are already signed in.
          </p>
          <p>
            IMPORTANT: You will be deemed to have used a self certification
            extension if you have:
          </p>
          <ul>
            <li>Submitted a piece of work that is not eligible.</li>
            <li>Submitted after the deadline.</li>
            <li>Used a self certification extension then submitted on time.</li>
            <li>
              Used an self certification extension but then decide to submit
              mitigating circumstances with evidence (formerly called
              extenuation).
            </li>
          </ul>
          <p>
            You will not be able to submit use for more than two self
            certification extensions per term.
          </p>
          <p>
            NOTE: Press the submit button once and wait. Do not reloading the
            page or using the back / forward buttons on the browser as it will
            lead to your information being lost. Please rely on the buttons
            within the pages.
          </p>

          {existingExtension.current ? (
            <p>You have already filled in your 2 extensions for this term</p>
          ) : (
            <Button
              style={{ backgroundColor: "#0da6a1" }}
              variant="contained"
              onClick={() => {
                setStep((setStep) => setStep + 1);
              }}
              //Disable button until existing extension check is complete
              disabled={!extensionCheckComplete}
            >
              Get Started
            </Button>
          )}
        </div>
        <div className="footer">
          <SignOutButton />
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
