import React from "react";
import "./StaffWorkflow.css";
import RequestTable from "./components/RequestTable/RequestTable";
import SignOutButton from "../shared_components/SignoutButton/SignOutButton";

function StaffWorkflow(props) {
  return (
    <div className="staff-workflow">
      <div className="staff-workflow-container">
        <div className="header">
          <h1>Extension Requests</h1>
        </div>
        <div className="body">
          <RequestTable />
        </div>
        <div className="footer">
          <div className="signout">
            <SignOutButton />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StaffWorkflow;
