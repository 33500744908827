/* eslint-disable no-lone-blocks */
import React, { useState } from "react";
import Button from "@mui/material/Button";

import AbsenseReason from "./form_pages/AbsenseReason";
import AssignmentTitle from "./form_pages/AssignmentTitle";
import Deadline from "./form_pages/Deadline";
import SelectModule from "./form_pages/SelectModule";
import SignOutButton from "../../../shared_components/SignoutButton/SignOutButton";

import { apiSubmitExtensionRequest } from "../../services/student.services";

import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";

import "./Form.css";

function Form({
  setStep,
  existingExtension,
  setNotificationMessage,
  setOpenNotification,
}) {
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({
    module: { moduleName: "" },
    assignmentTitle: "",
    deadline: "",
    reason: "",
  });
  const [moduleOptions, setModuleOptions] = useState([]);
  const [selectedReason, setSelectedReason] = useState("");
  const [showText, setShowText] = useState(false);
  const [buttonClicked, setbuttonClicked] = useState(false);
  const FormTitles = [
    "Select Module",
    "Assignment Title",
    "Submission Deadline",
    "Reason for  mitigating circumstances - self certification",
  ];

  //Submit multi-step form data
  const submitFormData = () => {
    apiSubmitExtensionRequest(formData).then((results) => {
      if (results.success) {
        //API responds with another check on an existing extension
        existingExtension.current = results.body;
        setStep((setStep) => setStep + 1);
      } else {
        setNotificationMessage(results.message);
        setOpenNotification(true);
      }
    });
  };

  // Form page controller - renders Form UI components depending on the current page
  const PageDisplay = () => {
    switch (page) {
      case 0:
        return (
          <SelectModule
            formData={formData}
            setFormData={setFormData}
            moduleOptions={moduleOptions}
            setModuleOptions={setModuleOptions}
            setNotificationMessage={setNotificationMessage}
            setOpenNotification={setOpenNotification}
          />
        );
      case 1:
        return (
          <AssignmentTitle formData={formData} setFormData={setFormData} />
        );
      case 2:
        return <Deadline formData={formData} setFormData={setFormData} />;
      case 3:
        return (
          <AbsenseReason
            formData={formData}
            setFormData={setFormData}
            selectedReason={selectedReason}
            setSelectedReason={setSelectedReason}
            showText={showText}
            setShowText={setShowText}
          />
        );

      default:
        setNotificationMessage(
          "Page transition error. Please contact system administrator"
        );
        setOpenNotification(true);
    }
  };

  return (
    <div className="form">
      <div className="form-container">
        {buttonClicked ? <LinearProgress color="success" /> : null}
        <div className="header">
          <h1>{FormTitles[page]}</h1>
        </div>
        <div className="progressbar">
          {/* Progress bar width changes depending on current page */}
          <div
            style={{
              width:
                page === 0
                  ? "25%"
                  : page === 1
                  ? "50%"
                  : page === 2
                  ? "75%"
                  : "100%",
            }}
          />
        </div>
        <div className="body">
          {PageDisplay()}
          {/* Previous button decrements the current page  */}
          <Button
            tabIndex={3}
            style={{ backgroundColor: "#0da6a1" }}
            variant="contained"
            disabled={page === 0 || buttonClicked}
            onClick={() => {
              setPage((currentPage) => currentPage - 1);
            }}
          >
            Prev
          </Button>
          <Button
            tabIndex={2}
            style={{ backgroundColor: "#0da6a1", cursor: "pointer" }}
            variant="contained"
            disabled={buttonClicked}
            onClick={() => {
              {
                /* Check if on last page */
              }
              if (page === FormTitles.length - 1) {
                {
                  /* Start submission process by checking if input fields are emptry */
                }
                if (Object.values(formData).includes("")) {
                  setNotificationMessage(
                    "Please ensure all input fields are filled in"
                  );
                  setOpenNotification(true);
                } else {
                  {
                    /* If all inputs filled, submit form and proceed to next page */
                  }
                  setbuttonClicked(true);
                  submitFormData(formData, existingExtension);
                }
              } else {
                {
                  /* Increment page if not on last page */
                }
                setPage((currentPage) => currentPage + 1);
              }
            }}
          >
            {/* Button label changes depending on current page */}
            {page === FormTitles.length - 1 ? "Submit" : "Next"}
            {/* This shows spinner when loading the Final page */}
            {buttonClicked ? (
              <CircularProgress size={25} color="secondary" />
            ) : null}
          </Button>
        </div>
        <div className="footer">
          <SignOutButton />
        </div>
      </div>
    </div>
  );
}

export default Form;
