import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import { apiGetStudentModules } from "../../../services/student.services";

function SelectModule({
  formData,
  setFormData,
  moduleOptions,
  setModuleOptions,
  setNotificationMessage,
  setOpenNotification,
}) {
  //Retrieve associated student modules
  const getStudentModules = () => {
    apiGetStudentModules().then((results) => {
      if (results.success) {
        setModuleOptions(results.body);
      } else {
        setNotificationMessage(results.message);
        setOpenNotification(true);
      }
    });
  };

  //Trigger on first render
  useEffect(() => {
    getStudentModules();
  }, []);

  return (
    <div className="select-module-container">
      <div className="prompt">Please select module from dropdown list</div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="module-label">Module</InputLabel>
        <Select
          labelId="module-label"
          label="Module"
          autoWidth
          value={formData.module.moduleName}
          onChange={(event) => {
            const module_name = event.target.value;
            const module = moduleOptions.find(
              (option) => option.moduleName === module_name
            );
            setFormData({ ...formData, module: module });
          }}
        >
          {moduleOptions.map((option) => (
            <MenuItem key={option.moduleName} value={option.moduleName}>
              {option.moduleCode} - {option.moduleName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default SelectModule;
