import React, { useState, useEffect, Fragment } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "./RequestTable.css";

import RequestDialog from "../RequestDialog/RequestDialog";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import NotificationBar from "../../../shared_components/NotificationBar/NotificationBar";

import {
  apiGetAllExtensionRequests,
  apiSaveExtensionRequestApproval,
} from "../../services/staff.service";

// Convert ISO date to human friendly format
const dateFormatter = (iso_format) => {
  if (iso_format !== undefined && iso_format !== "N/A" && iso_format !== null) {
    const formatted_date_time = new Date(iso_format).toString();
    return formatted_date_time.substring(0, 15);
  }
  return iso_format;
};

function RequestTable(props) {
  const [requestList, setRequestList] = useState({
    columns: [],
    rows: [],
  });
  const [openRequestDialog, setOpenRequestDialog] = useState(false);
  const [selectedExtensionRequest, setSelectedExtensionRequest] = useState({});
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [approvalAction, setApprovalAction] = useState("");
  const [approvalReason, setApprovalReason] = useState("");
  const [confirmApprovalAction, setConfirmApprovalAction] = useState(false);
  const [successAction, setSuccessAction] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  //Column Headers
  const columnHeaders = [
    {
      field: "studentCode",
      headerName: "Student Code",
      flex: 1,
      minWidth: 150,
      headerClassName: "column-header",
    },
    {
      field: "assignmentTitle",
      headerName: "Assignment Title",
      flex: 1,
      minWidth: 150,
      headerClassName: "column-header",
    },
    {
      field: "moduleCode",
      headerName: "Module Code",
      flex: 1,
      minWidth: 150,
      headerClassName: "column-header",
    },
    {
      field: "semesterCode",
      headerName: "Semester Code",
      flex: 1,
      minWidth: 150,
      headerClassName: "column-header",
    },
    {
      field: "moduleOwnerUsername",
      headerName: "Module Owner",
      flex: 1,
      minWidth: 150,
      headerClassName: "column-header",
    },
    {
      field: "documentCreatedDate",
      headerName: "Requested Date",
      flex: 1,
      minWidth: 150,
      headerClassName: "column-header",
      valueFormatter: (params) => {
        const formattedDate = dateFormatter(params.value);
        return formattedDate;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      headerClassName: "column-header",
    },
    {
      field: "view",
      headerName: "View",
      flex: 1,
      minWidth: 150,
      headerClassName: "column-header",
      renderCell: (params) => {
        const onClick = (e) => {
          const extension_request = params.row;
          handleRequestDialogOpen(
            extension_request,
            setApprovalReason,
            approvalReason
          );
        };

        return (
          <Button
            style={{ backgroundColor: "#0da6a1", padding: "3px 16px" }}
            variant="contained"
            onClick={onClick}
          >
            View
          </Button>
        );
      },
    },
  ];

  // Calls API to retrieve all extension requests
  const getAllExtensionRequests = () => {
    apiGetAllExtensionRequests().then((results) => {
      if (results.success) {
        //Set state using API data and preset column headers
        setRequestList({
          columns: columnHeaders,
          rows: results.body,
        });
      } else {
        setNotificationMessage(results.message);
        setOpenNotification(true);
      }
    });
  };

  // Calls API to save extension request approval decision
  const saveExtensionRequestApproval = () => {
    setNotificationMessage("Submitting approval decision...");
    setOpenNotification(true);

    apiSaveExtensionRequestApproval(
      selectedExtensionRequest,
      approvalAction,
      approvalReason
    ).then((results) => {
      if (results.success) {
        // Close all dialog boxes and refresh table to include new decision
        handleConfirmationDialogClose();
        handleRequestDialogClose();
        setSuccessAction(true);
      }
      setNotificationMessage(results.message);
      setOpenNotification(true);
    });
  };

  //Trigger on first render
  useEffect(() => {
    getAllExtensionRequests();
  }, []);

  //Trigger after approval decision is made
  useEffect(() => {
    if (successAction) {
      getAllExtensionRequests();
    }
    setSuccessAction(false);
  }, [successAction]);

  // Trigger after a approval confirmation on the dialogue box is made
  useEffect(() => {
    if (confirmApprovalAction) {
      saveExtensionRequestApproval();
    }
    setConfirmApprovalAction(false);
  }, [confirmApprovalAction]);

  //Open request dialog
  const handleRequestDialogOpen = (extension_request) => {
    setOpenRequestDialog(true);
    setSelectedExtensionRequest(extension_request);
  };

  //Close request dialog
  const handleRequestDialogClose = () => {
    setOpenRequestDialog(false);
  };

  //Open confirmation dialog
  const handleConfirmationDialogOpen = (incomingApprovalAction) => {
    setApprovalAction(incomingApprovalAction);
    setOpenConfirmationDialog(true);
  };

  //Close confirmation dialog
  const handleConfirmationDialogClose = () => {
    setOpenConfirmationDialog(false);
  };

  //Confirm approval action
  const handleConfirmationYes = () => {
    setConfirmApprovalAction(true);
    handleConfirmationDialogClose();
  };

  //Close notification close
  const handleNotificationClose = () => {
    setOpenNotification(false);
  };

  return (
    <Fragment>
      <div className="request-table">
        <Box
          sx={{
            "& .column-header": {
              fontWeight: "bold",
              fontSize: 16,
            },
          }}
        >
          <DataGrid
            rows={requestList.rows}
            columns={requestList.columns}
            pageSize={8}
            rowsPerPageOptions={[5, 10]}
            autoHeight={true}
            getRowId={(row) => row.requestID}
            components={{ Toolbar: GridToolbar }}
          />
        </Box>
      </div>
      <div className="request-dialog">
        <RequestDialog
          openRequestDialog={openRequestDialog}
          handleRequestDialogClose={handleRequestDialogClose}
          selectedExtensionRequest={selectedExtensionRequest}
          handleConfirmationDialogOpen={handleConfirmationDialogOpen}
          setApprovalAction={setApprovalAction}
        />
      </div>
      <div className="request-decision-confirmation">
        <ConfirmationDialog
          openConfirmationDialog={openConfirmationDialog}
          handleConfirmationDialogClose={handleConfirmationDialogClose}
          approvalAction={approvalAction}
          handleConfirmationYes={handleConfirmationYes}
          setApprovalReason={setApprovalReason}
          approvalReason={approvalReason}
        />
      </div>
      <div className="notification-bar">
        <NotificationBar
          openNotification={openNotification}
          handleNotificationClose={handleNotificationClose}
          notificationMessage={notificationMessage}
        />
      </div>
    </Fragment>
  );
}

export default RequestTable;
