import React from "react";
import "./SubmissionPage.css";
import SignOutButton from "../../../shared_components/SignoutButton/SignOutButton";

function SubmissionPage({ existingExtension }) {
  return (
    <div className="submission-page">
      <div className="submission-page-container">
        <div className="logo"></div>
        <div className="header">
          <h1>
            {" "}
            Mitigating circumstances - self certification Successfully Submitted
          </h1>
        </div>
        <div className="body">
          <p>
            Thank you for filling in this mitigating circumstances - self
            certification form. The module leader has been informed.
          </p>
          <p>Mitigating circumstances - self certification has been applied.</p>
        </div>
        <div className="footer">
          <SignOutButton />
        </div>
      </div>
    </div>
  );
}

export default SubmissionPage;
