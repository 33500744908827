import { useEffect, useState } from "react";
import "./App.css";

import { Amplify, Auth, Hub } from "aws-amplify";
import { Card } from "@aws-amplify/ui-react";
import Button from "@mui/material/Button";

import StudentWorkflow from "./student/StudentWorkflow";
import StaffWorkflow from "./staff/StaffWorkflow";
import SignOutButton from "./shared_components/SignoutButton/SignOutButton";
import awsconfig from "./aws-config";
import LogoBar from "./shared_components/LogoBar/LogoBar";

Amplify.configure(awsconfig);

const AuthStateApp = () => {
  const [user, setUser] = useState();
  const [role, setRole] = useState();

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => {
            const user_atributes = userData.attributes;
            determineRole(user_atributes);
            setUser(userData);
          });
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
          break;
      }
    });
  }, []);

  //Determine role from incomming user attributes
  function determineRole(user_atributes) {
    try {
      // Attempt to retrieve user type attribute
      const user_type = user_atributes["custom:user_type"];
      if (user_type === "student") setRole("student");
      else if (user_type === "staff") setRole("staff");
      else setRole("error");
    } catch (err) {
      setRole("error");
    }
  }

  //Get user data from authenticated session
  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }

  return user && role === "student" ? ( //Render if student logs in
    <div className="App">
      <div className="App-container">
        <LogoBar />
        <StudentWorkflow />
      </div>
      <div className="App-footer"></div>
    </div>
  ) : user && role === "tlsrstudent" ? ( //Render if student logs in
    <div className="App">
      <div className="App-container">
        <LogoBar />
        <StudentWorkflow />
      </div>
      <div className="App-footer"></div>
    </div>
  ) : user && role === "staff" ? ( //Render if staff logs in
    <div className="App">
      <div className="App-container">
        <LogoBar />
        <StaffWorkflow />
      </div>
      <div className="App-footer"></div>
    </div>
  ) : user && role === "error" ? ( //Render if user is logged in with a indeterminable role
    <div className="App">
      <p className="error">
        Error with authentication. Role is not determinable, please contact an
        administrator.
      </p>
      <div className="App-footer">
        <SignOutButton />
      </div>
    </div>
  ) : (
    //Render if error or no role is logged in
    <div className="App">
      <div className="App-container">
        <LogoBar />
        <p>To use this application you need to be signed in</p>
        <Card>
          <Button
            variant="contained"
            onClick={() => Auth.federatedSignIn({ provider: "AzureAD" })}
          >
            Sign In
          </Button>
        </Card>
      </div>
    </div>
  );
};

export default AuthStateApp;
